import React, {Fragment} from 'react';
import './Projects.css';
import conexp from './pics/conexp.svg';
import bibsonomy from './pics/bibsonomy.png';
import topicFlow from './pics/topic-flow.png';
import fairdienste from './pics/fairdienste.png';
import orgReveal from './pics/reveal-js.png';
import engagement from './pics/volunteer-652383_640.jpg';
import { useMediaQuery } from 'react-responsive';

function ConexpImg(){
    return (<div className="project-image-container">
              <div className="project-image">
                <div id="conexp-img-container">
                 <center>
                   <a href="https://github.com/tomhanika/conexp-clj"><img src={conexp} id="conexp-img" alt="Conexp-clj Framework" width="90%"/></a>
                 </ center>
                </div>
              </div>
            </div>);
}

function ConexpDescription() {
    return (
        <div className="project-description-container">
              <div className="project-description">
              <h2>Hierarchical Data Analysis</h2><br />
              <p> All the code that we use to analyze hierarchical data structures is
                provided in the conexp-clj, project, a research framework for Formal
                Concept Analysis in Clojure. I contributed by developing functionality for:</p>
                  <ul>
                    <li>Core Structures in bipartite networks</li>
                    <li>Data Reduction of Hierarchical Structures</li>
                    <li>Motifs in Hierarchical Structures</li>
                  </ul>
              </div>
            </div>
    );
}

function Conexp() {
    const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
    return (
        <div className="project-left">
            {isDesktop ? 
                <Fragment>
                  <ConexpImg />
                  <ConexpDescription />
                </Fragment>:
                <Fragment>
                  <ConexpDescription />
                  <ConexpImg /> 
                </Fragment>
                }
        </div>
    );
}

function BibSonomyImg() {
    return (
            <div className="project-image-container">
              <div className="project-image">
                <a className="project-image-link" href="https://www.bibsonomy.org/"><img src={bibsonomy} alt="BibSonomy Website" width="100%" /></a>
              </div>
            </div>
    );
}

function BibSonomyDescription() {
    return (
            <div className="project-description-container">
              <div className="project-description">
              <h2>BibSonomy</h2><br />
<p> BibSonomy is a social bookmark and publication sharing
                  system with over a million registered users. I contributed by developing functionality for: 
                </p>
                <ul>
                  <li>Person Entity Matching</li>
                  <li>PhD Advisor Recommendation</li>
                </ul>
              </div>
            </div>
);
}

function BibSonomy() {
    const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
    return (
        <div className="project-right">
            {isDesktop ? 
                <Fragment>
                  <BibSonomyDescription />
                  <BibSonomyImg /> 
                </Fragment>:
                <Fragment>
                  <BibSonomyDescription />
                  <BibSonomyImg /> 
                </Fragment>
                }
        </div>
    );
}

function TopicFlowImg() {
    return (
<div className="project-image-container">
              <div className="project-image">
                  <a className="project-image-link" href="https://flow.sci-rec.org/">
                    <img src={topicFlow} alt="Research Topic Flow" width="100%"/>
                  </a>
              </div>
            </div>
    );
}

function TopicFlowDescription() {
    return (
            <div className="project-description-container">
              <div className="project-description">
              <h2>Research Topic Flow</h2><br/>
                <p>
                  We provide an interactive WebApp accompanying our work on
                  the Topic Flow Network, which enables us to study topic
                  specific flow of expertise between scientific authors.
                </p>
              </div>
            </div>
);
}

function TopicFlow() {
    const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
    return (
        <div className="project-left">
            {isDesktop ? 
                <Fragment>
                  <TopicFlowImg />
                  <TopicFlowDescription />
                </Fragment>:
                <Fragment>
                  <TopicFlowDescription />
                  <TopicFlowImg /> 
                </Fragment>
                }
        </div>
    );
}

function FairdiensteImg() {
    return (
            <div className="project-image-container">
              <div className="project-image">
                <a className="project-image-link" href="https://www.forschung-it-sicherheit-kommunikationssysteme.de/projekte/fairdienste">
                 <img src={fairdienste} alt="Fairdienste Website" width="100%"/>
                </a>
              </div>
            </div>
    );
}

function FairdiensteDescription() {
    return (
            <div className="project-description-container">
              <div className="project-description">
              <h2>Fairdienste</h2><br />
<p>
                  In the project fair digital services: "Ko-Valuation
                  in der Gestaltung datenökonomischer Geschäftsmodelle
                  (FAIRDIENSTE)" (Co-evaluation in the design of
                  data-economic business models), an interdisciplinary
                  approach is pursued that includes both sociological and
                  (business) computer science aspects. With my research, I am
                  contributing to the project by developing a knowledge
                  representation for controversies in 
                  online journalism.
                </p>
              </div>
            </div>
    );
}


function Fairdienste() {
    const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
    return (
        <div className="project-right">
            {isDesktop ? 
                <Fragment>
                  <FairdiensteDescription />
                  <FairdiensteImg /> 
                </Fragment>:
                <Fragment>
                  <FairdiensteDescription />
                  <FairdiensteImg /> 
                </Fragment>
                }
        </div>
    );
}

function OrgRevealImg() {
    const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
    return (
<div className="project-image-container">
              <div className="project-image">
                  <a className="project-image-link" href="https://www.hirthjo.org/fca-org-reveal/presentation.html">
                    <img src={orgReveal} alt="Org Reveal Presentation" width="100%"/>
                  </a>
              </div>
            </div>
    );
}

function OrgRevealDescription() {
    return (
<div className="project-description-container">
              <div className="project-description">
              <h2>Interactive Web Presentations</h2><br/>
<p>
                  I have create an interactive presentation that introduces
                  conceptual structures using <a href="https://orgmode.org/"
                                                 title="org-mode">org-mode</a> and <a href="https://revealjs.com/" title="reveal.js">
                    reveal.js
                  </a>. The code can be found in my <a href="https://codeberg.org/hirthjo/fca-org-reveal" title="Code">Codeberg</a> repository.
                </p>
              </div>
            </div>
    );
}


function OrgReveal() {
    const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
    return (
        <div className="project-left">
            {isDesktop ? 
                <Fragment>
                  <OrgRevealImg />
                  <OrgRevealDescription />
                </Fragment>:
                <Fragment>
                  <OrgRevealDescription />
                  <OrgRevealImg />
                </Fragment>
                }
        </div>
    );
}

function EngagementImg() {
    return (
            <div className="project-image-container">
              <div className="project-image">
                 <img src={engagement} alt="Volunteer Image" width="100%"/>
              </div>
            </div>
    );
}

function EngagementDescription() {
    return (
<div className="project-description-container">
              <div className="project-description">
              <h2>Extracurricular Projects</h2><br />
                <ul>
                  <li>Supervision of annual two-week internships at the University of Kassel
                    <ul>
                      <li>Simulate traffic using cellular automata (2016)</li>
                      <li>Analyze the twitter social network (2018-today)</li>
                    </ul>
                  </li>
                  <li>I give a lecture on programming with Clojure (2019-today)</li>
                  <li>During
                     the <a href="https://en.wikipedia.org/wiki/Documenta"
                     title="documenta">documenta</a> 2022
        I gave a <a href="https://sfn-kassel.de/aktuelles/termine/432">100
                     days of STEM</a> workshop on <em>"Which is
                  better? Scale, organise and understand data properly"</em>.</li>
                </ul>
              </div>
            </div>
    );
}


function Engagement() {
    const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
    return (
        <div className="project-right">
            {isDesktop ? 
                <Fragment>
                  <EngagementDescription />
                  <EngagementImg /> 
                </Fragment>:
                <Fragment>
                  <EngagementDescription />
                  <EngagementImg /> 
                </Fragment>
                }
        </div>
    );
}

function Projects() {
    return (
        <section id="projects" className="projects-section">
            <Conexp />
            <BibSonomy />
            <TopicFlow />
            <Fairdienste />
            <OrgReveal />
            <Engagement />
        </section>);
}

export default Projects;
