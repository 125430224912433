import React, {Fragment} from 'react';
import './Research.css';
import {  FileEarmarkCodeFill } from 'react-bootstrap-icons';
import { useMediaQuery } from 'react-responsive';
import tmsai from './pics/tm.png';
import fca from './pics/fca.png';
import sna from './pics/sna.png';
import nn from './pics/nn.png';
import tm from './pics/topic_model_embedding.png';

// @ts-ignore
function setAIStyles(setStyleHandler){
    setStyleHandler(
        {"AI-TM": "var(--light-white)",
         "AI-SAI": "var(--light-white)",
         "TM-SNA": "var(--light-white)",
         "TM-TMSAI": "var(--light-white)",
         "SAI-TMSAI": "var(--light-white)",
         "SAI-DL": "var(--light-white)",
         "SNA-BOT": "var(--light-white)",
         "TMSAI-BOT": "var(--light-white)",
         "DL-BOT": "var(--light-white)",
         "AI": "var(--light-white)",
         "TM": "var(--light-white)",
         "SAI": "var(--light-white)",
         "SNA": "var(--light-white)",
         "TMSAI": "var(--light-white)",
         "DL": "var(--light-white)",
         "BOT": "var(--light-white)",
         "TM-label": "var(--light-white)",
         "SAI-label": "var(--light-white)",
         "SNA-label": "var(--light-white)",
         "DL-label": "var(--light-white)",
         "AI-label": "var(--light-white)"
        }
    );
}

// @ts-ignore
function setSAIStyles(setStyleHandler){
    setStyleHandler(
        {"AI-TM": "var(--bg-color)",
         "AI-SAI": "var(--light-white)",
         "TM-SNA": "var(--bg-color)",
         "TM-TMSAI": "var(--bg-color)",
         "SAI-TMSAI": "var(--bg-color)",
         "SAI-DL": "var(--bg-color)",
         "SNA-BOT": "var(--bg-color)",
         "TMSAI-BOT": "var(--bg-color)",
         "DL-BOT": "var(--bg-color)",
         "AI": "var(--light-white)",
         "TM": "var(--bg-color)",
         "SAI": "var(--light-white)",
         "SNA": "var(--bg-color)",
         "TMSAI": "var(--bg-color)",
         "DL": "var(--bg-color)",
         "BOT": "var(--bg-color)",
         "TM-label": "var(--bg-color)",
         "SAI-label": "var(--light-white)",
         "SNA-label": "var(--bg-color)",
         "DL-label": "var(--bg-color)",
         "AI-label": "var(--light-white)"
        }
    );
}

// @ts-ignore
function setDLStyles(setStyleHandler){
    setStyleHandler(
        {"AI-TM": "var(--bg-color)",
         "AI-SAI": "var(--light-white)",
         "TM-SNA": "var(--bg-color)",
         "TM-TMSAI": "var(--bg-color)",
         "SAI-TMSAI": "var(--bg-color)",
         "SAI-DL": "var(--light-white)",
         "SNA-BOT": "var(--bg-color)",
         "TMSAI-BOT": "var(--bg-color)",
         "DL-BOT": "var(--bg-color)",
         "AI": "var(--light-white)",
         "TM": "var(--bg-color)",
         "SAI": "var(--light-white)",
         "SNA": "var(--bg-color)",
         "TMSAI": "var(--bg-color)",
         "DL": "var(--light-white)",
         "BOT": "var(--bg-color)",
         "TM-label": "var(--bg-color)",
         "SAI-label": "var(--light-white)",
         "SNA-label": "var(--bg-color)",
         "DL-label": "var(--light-white)",
         "AI-label": "var(--light-white)"
        }
    );
}

// @ts-ignore
function setTMStyles(setStyleHandler){
    setStyleHandler(
        {"AI-TM": "var(--light-white)",
         "AI-SAI": "var(--bg-color)",
         "TM-SNA": "var(--bg-color)",
         "TM-TMSAI": "var(--bg-color)",
         "SAI-TMSAI": "var(--bg-color)",
         "SAI-DL": "var(--bg-color)",
         "SNA-BOT": "var(--bg-color)",
         "TMSAI-BOT": "var(--bg-color)",
         "DL-BOT": "var(--bg-color)",
         "AI": "var(--light-white)",
         "TM": "var(--light-white)",
         "SAI": "var(--bg-color)",
         "SNA": "var(--bg-color)",
         "TMSAI": "var(--bg-color)",
         "DL": "var(--bg-color)",
         "BOT": "var(--bg-color)",
         "TM-label": "var(--light-white)",
         "SAI-label": "var(--bg-color)",
         "SNA-label": "var(--bg-color)",
         "DL-label": "var(--bg-color)",
         "AI-label": "var(--light-white)"
        }
    );
}

// @ts-ignore
function setSNAStyles(setStyleHandler){
    setStyleHandler(
        {"AI-TM": "var(--light-white)",
         "AI-SAI": "var(--bg-color)",
         "TM-SNA": "var(--light-white)",
         "TM-TMSAI": "var(--bg-color)",
         "SAI-TMSAI": "var(--bg-color)",
         "SAI-DL": "var(--bg-color)",
         "SNA-BOT": "var(--bg-color)",
         "TMSAI-BOT": "var(--bg-color)",
         "DL-BOT": "var(--bg-color)",
         "AI": "var(--light-white)",
         "TM": "var(--light-white)",
         "SAI": "var(--bg-color)",
         "SNA": "var(--light-white)",
         "TMSAI": "var(--bg-color)",
         "DL": "var(--bg-color)",
         "BOT": "var(--bg-color)",
         "TM-label": "var(--light-white)",
         "SAI-label": "var(--bg-color)",
         "SNA-label": "var(--light-white)",
         "DL-label": "var(--bg-color)",
         "AI-label": "var(--light-white)"
        }
    );
}

// @ts-ignore
function setTMSAIStyles(setStyleHandler){
    setStyleHandler(
        {"AI-TM": "var(--light-white)",
         "AI-SAI": "var(--light-white)",
         "TM-SNA": "var(--bg-color)",
         "TM-TMSAI": "var(--light-white)",
         "SAI-TMSAI": "var(--light-white)",
         "SAI-DL": "var(--bg-color)",
         "SNA-BOT": "var(--bg-color)",
         "TMSAI-BOT": "var(--bg-color)",
         "DL-BOT": "var(--bg-color)",
         "AI": "var(--light-white)",
         "TM": "var(--light-white)",
         "SAI": "var(--light-white)",
         "SNA": "var(--bg-color)",
         "TMSAI": "var(--light-white)",
         "DL": "var(--bg-color)",
         "BOT": "var(--bg-color)",
         "TM-label": "var(--light-white)",
         "SAI-label": "var(--light-white)",
         "SNA-label": "var(--bg-color)",
         "DL-label": "var(--bg-color)",
         "AI-label": "var(--light-white)"
        }
    );
}

// @ts-ignore
function handleConceptClick(setStylesFN, setStyleHandler, setTopicHandler, currentTopic){
    return () =>{
        setStylesFN(setStyleHandler);
        setTopicHandler(currentTopic);
    }
}

// @ts-ignore
const ContentLattice = ({currentTopic, setTopicHandler, contentLatticeStyles, setStyleHandler}) => {
    return (
        <svg id="bv-topics" viewBox="-5 -5 75 60" width="90%" height="100%">
            <line id="AI-TM" x1="30" y1="10" x2="15" y2="20" stroke={contentLatticeStyles["AI-TM"]} /> // AI -- TM 
            <line id="AI-SAI" x1="30" y1="10" x2="45" y2="20" stroke={contentLatticeStyles["AI-SAI"]} /> // AI -- SAI 
            
            <line id="TM-SNA" x1="15" y1="20" x2="15" y2="35" stroke={contentLatticeStyles["TM-SNA"]} /> // TM -- SNA 
            <line id="TM-TMSAI" x1="15" y1="20" x2="30" y2="30" stroke={contentLatticeStyles["TM-TMSAI"]} /> // TM -- TMSAI
            <line id="SAI-TMSAI" x1="45" y1="20" x2="30" y2="30" stroke={contentLatticeStyles["SAI-TMSAI"]} /> // SAI -- TMSAI 
            <line id="SAI-DL" x1="45" y1="20" x2="45" y2="35" stroke={contentLatticeStyles["SAI-DL"]} /> // SAI -- DL
            
            <line id="SNA-BOT" x1="15" y1="35" x2="30" y2="45" stroke={contentLatticeStyles["SNA-BOT"]} /> // SNA -- BOT 
            <line id="TMSAI-BOT" x1="30" y1="30" x2="30" y2="45" stroke={contentLatticeStyles["TMSAI-BOT"]} /> /// TMSAI -- BOT
            <line id="DL-BOT" x1="45" y1="35" x2="30" y2="45" stroke={contentLatticeStyles["DL-BOT"]} /> // DL -- BOT

            <circle className="concept" id="AI" cx="30" cy="10" r="3" stroke="white" strokeWidth="0" fill={contentLatticeStyles["AI"]} onClick={handleConceptClick(setAIStyles, setStyleHandler, setTopicHandler, "AI")}/> // AI 
            <circle className="concept" id="TM" cx="15" cy="20" r="3" stroke="white" strokeWidth="0" fill={contentLatticeStyles["TM"]} onClick={handleConceptClick(setTMStyles, setStyleHandler, setTopicHandler, "TM")}/> // topic model
            <circle className="concept" id="SAI" cx="45" cy="20" r="3" stroke="white" strokeWidth="0" fill={contentLatticeStyles["SAI"]} onClick={handleConceptClick(setSAIStyles, setStyleHandler, setTopicHandler, "SAI")} /> // sym AI 
            
            <circle className="concept" id="SNA" cx="15" cy="35" r="3" stroke="white" strokeWidth="0" fill={contentLatticeStyles["SNA"]} onClick={handleConceptClick(setSNAStyles, setStyleHandler, setTopicHandler, "SNA")}/> // SNA 
            <circle className="concept" id="TMSAI" cx="30" cy="30" r="3" stroke="white" strokeWidth="0" fill={contentLatticeStyles["TMSAI"]} onClick={handleConceptClick(setTMSAIStyles, setStyleHandler, setTopicHandler, "TMSAI")}/> // topic and symAI
            <circle className="concept" id="DL" cx="45" cy="35" r="3" stroke="white" strokeWidth="0" fill={contentLatticeStyles["DL"]} onClick={handleConceptClick(setDLStyles, setStyleHandler, setTopicHandler, "DL")}/> // DL 
            
            <circle className="concept" id="BOT" cx="30" cy="45" r="3" stroke="white" strokeWidth="0" fill={contentLatticeStyles["BOT"]} onClick={handleConceptClick(setAIStyles, setStyleHandler, setTopicHandler, "AI")}/> // bot 
            
            <text id="TM-label" x="15" y="20" fill={contentLatticeStyles["TM-label"]} fontSize="0.3em">
              <tspan x="-3" dy="-1.4em">Topic</tspan>
              <tspan x="-5" dy="1.2em">Models</tspan>
            </text>
            
            <text id="SAI-label" x="45" y="20" fill={contentLatticeStyles["SAI-label"]} fontSize="0.3em">
              <tspan x="48" dy="-1.4em">Symbolic</tspan>
              <tspan x="55" dy="1.2em">AI</tspan>
            </text>
            
            <text id="SNA-label" x="-2" y="33" fill={contentLatticeStyles["SNA-label"]} fontSize="0.3em">SNA</text>
            
            <text id="DL-label" x="48" y="37" fill={contentLatticeStyles["DL-label"]} fontSize="0.3em">
              <tspan x="53" dy="-1.4em">Deep</tspan>
              <tspan x="50" dy="1.2em">learning</tspan>
            </text>
            <text id="AI-label" x="28" y="3" fill={contentLatticeStyles["AI-label"]} fontSize="0.3em">AI</text>
          </svg>
    );
}

const AIResearch = () => {
    const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
    return <div className="paper-content-container">
              <div className="paper-center-content">
                <div className="paper-content-header">
                  <h3 className="paper-content-heading"><b>Artificial Intelligence</b></h3>
                  <a href="https://www.bibsonomy.org/bib/user/hirth/myown" className="me-4 text-reset bib-link" title="Bibtex SAI" style={{float: "right", padding: "10px"}}>
                    <FileEarmarkCodeFill />
                  </a>
                </div>
                <div className="paper-content">
                  <p>
        Within AI I am most interested in methods from explainable 
        artificial intelligence (XAI), which focus on making AI systems
        transparent and understandable. I prioritize techniques and processes
        that enable human users to comprehend the outputs produced
        by machine learning algorithms. The techniques I developed are rooted in 
        algebraic (ordinal) data analysis and present patterns found in the data
        via hierarchical structures.
                 </p>
        {isDesktop ?
                 <p id="content-lattice-info">
                   One such structure is depicted on left on can be used to navigate to some 
                   contributions of us by <b style={{color: "rgba(0,118,142,1)"}}>clicking on the circles in the sidemenu</b>.
                 </p>:
         <div />
            }
                </div>
              </div>
            </div>;
}

const TMResearch = () => {
    return <div className="paper-content-container">
              <div className="paper-center-content">
                <div className="paper-content-header">
                  <h3 className="paper-content-heading"><b>Topic Models</b></h3>
                  <a href="https://www.bibsonomy.org/bib/user/hirth/tm" className="me-4 text-reset bib-link" title="Bibtex SAI" style={{float: "right", padding: "10px"}}>
                    <FileEarmarkCodeFill />
                  </a>
                </div>
                <div className="paper-content">
                  <p>
                  To the realm of topic models we contributed in two ways. 
                  First, we studied how topical expertise spreads in collaboration
                  networks. Our results provide insights into research behaviour 
                  development over time and identified authorities in different 
                  research fields.

                  Second, we developed novel hierarchical and geometric 
                  explanations for topic models, authors and scientific venues.
                 </p>
                </div>
              </div>
        <div className="research-img-container">
          <a href="https://sci-rec.org/maps">
            <img className="research-img" src={tm} alt="The Topic Space" />
          </a>
          <hr style={{margin: "0px"}}/>
          <span><a href="https://arxiv.org/abs/2204.11859"><em>Mapping Research Trajectories</em>, Schäfermeier,
Stumme, and Hanika (2022)</a></span>
        </div>
            </div>;
}

const TMSAIResearch = () => {
    return <div className="paper-content-container"> 
              <div className="paper-center-content">
                <div className="paper-content-header">
                  <h3 className="paper-content-heading"><b>Topic Models and Symbolic Artificial Intelligence</b></h3>
                  <a href="https://www.bibsonomy.org/bib/user/hirth/tmsai" className="me-4 text-reset bib-link" title="Bibtex SAI" style={{float: "right", padding: "10px"}}>
                    <FileEarmarkCodeFill />
                  </a>
                </div>
                <div className="paper-content">
                  <p>
                  We developed a novel explanation method for topic models 
                  via hierarchical (ordinal) structures. These show hierarchical 
                  dependencies between topics among articles. Moreover, they reviel 
                  geometric patterns between topics that we used to generate maps 
                  of high dimensional topic spaces.
                  </p>
                </div>
              </div>
      <div className="research-img-container">
          <img className="research-img" src={tmsai} alt="Geometric Structure of Topic Models" />
          <hr style={{margin: "0px"}}/>
          <span><a href="https://github.com/hirthjo/The-Geometric-Structure-of-Topic-Models"><em>The Geometric Structure of Topic Models</em>, Hirth, and Hanika (2024)</a></span>
        </div>
      </ div>;
}

const SNAResearch = () => {
    return <div className="paper-content-container">
              <div className="paper-center-content">
                <div className="paper-content-header">
                  <h3 className="paper-content-heading"><b>
Topic Models and Social Network Analysis</b></h3>
                  <a href="https://www.bibsonomy.org/bib/user/hirth/sna" className="me-4 text-reset bib-link" title="Bibtex SAI" style={{float: "right", padding: "10px"}}>
                    <FileEarmarkCodeFill />
                  </a>
                </div>
                <div className="paper-content">
                  <p>
                  In scientometrics, scientific collaboration is often analyzed by
                  means of co-authorships. An aspect which is often overlooked and more
                  difficult to quantify is the flow of expertise between authors from
                  different research topics, which is an important part of scientific
                  progress. With the <a href="https://flow.sci-rec.org/" title="Topic
                                                                                Flow Network WebApp"> Topic Flow Network</a> we propose a graph structure for
                  the analysis of research topic flows between scientific authors and
                  their respective research fields. 
                  </p>
                </div>
              </div>
    
      <div className="research-img-container">
          <img className="research-img" src={sna} alt="Topic Flow Network" />
          <hr style={{margin: "0px"}}/>
          <span><a href="https://arxiv.org/abs/2206.07980"><em>Research Topic Flows in Co-Authorship Networks</em>, Schäfermeier, Hirth, and Hanika (2022)</a></span>
        </div>
            </div>;
}

const SAIResearch = () => {
    return  <div className="paper-content-container">
              <div className="paper-center-content">
                <div className="paper-content-header">
                  <h3 className="paper-content-heading"><b>Symbolic Artificial Intelligence</b></h3>
                  <a href="https://www.bibsonomy.org/bib/user/hirth/sai" className="me-4 text-reset bib-link" title="Bibtex SAI" style={{float: "right", padding: "10px"}}>
                    <FileEarmarkCodeFill />
                  </a>
                </div>
                <div className="paper-content">
                  <p>
                    With our work on symbolic artificial intelligence, we
                    focus on providing explanations to machine learning
                    methods in a symbolic language. For this, we employ
                    algebraic (ordinal) methods that provide representations
                    of the data via hierarchical structures. 
                  </p>
                </div>
              </div>
      <div className="research-img-container">
          <img className="research-img" src={fca} alt="Concept Lattice" />
          <hr style={{margin: "0px"}}/>
          <span><a href="https://github.com/hirthjo/The-Geometric-Structure-of-Topic-Models"><em>The Geometric Structure of Topic Models</em>, Hirth, and Hanika (2024)</a></span>
        </div>
            </div>;
}

const DLResearch = () => {
    return <div className="paper-content-container">
              <div className="paper-center-content">
                <div className="paper-content-header">
                  <h3 className="paper-content-heading"><b>Symbolic Artificial Intelligence in Deep Learning</b></h3>
                  <a href="https://www.bibsonomy.org/bib/user/hirth/dl" className="me-4 text-reset bib-link" title="Bibtex SAI" style={{float: "right", padding: "10px"}}>
                    <FileEarmarkCodeFill />
                  </a>
                </div>
                <div className="paper-content">
                  <p>
                  In the field of deep learning, we studied several 
                  neural networks trained on
                  the ImageNet dataset. We show how these can be analyzed
                  and compared via hierarchical structures. Our approach
                  automatically extracts (formal) concepts contained in a
                  neural network and translates them into an interpretable
                  symbolic space.
                  </p>
                </div>
              </div>
      <div className="research-img-container">
          <img className="research-img" src={nn} alt="Symbolic Space of Neural Networks" />
          <hr style={{margin: "0px"}}/>
          <span><a href="https://arxiv.org/abs/2209.13517"><em>Formal Conceptual Views in Neural Networks</em>, Hirth, and Hanika (2022)</a></span>
        </div>
            </div>;
}

// @ts-ignore
const ResearchContent = ({currentTopic}) => {
    return <div className="content-container">
            {currentTopic === "AI" ? <AIResearch /> :
              currentTopic === "TM" ?  <TMResearch />:
                currentTopic === "SAI" ?  <SAIResearch />:
                  currentTopic === "SNA" ?  <SNAResearch />:
                    currentTopic === "DL" ?  <DLResearch />:
                      currentTopic === "TMSAI" ?  <TMSAIResearch />: <AIResearch />
              }
           </div>;
}


function Research() {
    const [activeTopic, setActiveTopic] = React.useState('AI');
    const [contentLatticeStyles, setContentLatticeStyles] = React.useState(
        {"AI-TM": "var(--light-white)",
         "AI-SAI": "var(--light-white)",
         "TM-SNA": "var(--light-white)",
         "TM-TMSAI": "var(--light-white)",
         "SAI-TMSAI": "var(--light-white)",
         "SAI-DL": "var(--light-white)",
         "SNA-BOT": "var(--light-white)",
         "TMSAI-BOT": "var(--light-white)",
         "DL-BOT": "var(--light-white)",
         "AI": "var(--light-white)",
         "TM": "var(--light-white)",
         "SAI": "var(--light-white)",
         "SNA": "var(--light-white)",
         "TMSAI": "var(--light-white)",
         "DL": "var(--light-white)",
         "BOT": "var(--light-white)",
         "TM-label": "var(--light-white)",
         "SAI-label": "var(--light-white)",
         "SNA-label": "var(--light-white)",
         "DL-label": "var(--light-white)",
         "AI-label": "var(--light-white)"
        }
    );

    const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
        // <div className="research-heading-container">-->
        //       <h1 className="research-heading">Research Projects</h1>
        //        </div>
              
    return (<Fragment>
            {isDesktop ? 
              <div id="research-component">
                <center id="content-lattice-container">
                  <ContentLattice currentTopic={activeTopic} setTopicHandler={setActiveTopic} contentLatticeStyles={contentLatticeStyles} setStyleHandler={setContentLatticeStyles}/>
                </center> 
              <ResearchContent currentTopic={activeTopic}/>
              </div>
                :
             <Fragment>
              <div className="content-container even-research">
                <AIResearch /> 
              </div>
              <div className="content-container odd-research">
                <TMResearch />
              </div>
              <div className="content-container even-research">
                <SAIResearch />
              </div>
              <div className="content-container odd-research">
                <SNAResearch />
              </div>
              <div className="content-container even-research">
                <DLResearch />
              </div>
              <div className="content-container odd-research">
                <TMSAIResearch />
              </div>
            </ Fragment>}
        </ Fragment>
    );
}

export default Research;
